import { gql } from 'graphql-tag'

export const makesCar = gql`
  query makesCar {
    makes(
      onlyAvailables: true
      active: true
      vehicleTypeIn: ["car", "van"]
      slugIn: ["citroen", "dacia", "fiat", "hyundai", "kia", "peugeot", "renault", "seat"]
    ) {
      nodes {
        name
        slug
        logo {
          file
        }
      }
    }
  }
`

export const makesMotorbike = gql`
  query makesMotorbike {
    makes(
      onlyAvailables: true
      active: true
      vehicleTypeIn: "motorbike"
      slugIn: ["aprilia", "benelli", "honda", "kawasaki", "keeway", "kymco", "sym", "ktm"]
    ) {
      nodes {
        name
        slug
        logo {
          file
        }
      }
    }
  }
`

export const relevanceCarVn = gql`
  query relevanceCarVn($financialEntityName: String!) {
    vehicles: models(
      vehicles: { categoryIn: "VN" }
      sortByRelevance: true
      page: 1
      perPage: 8
      vehicleTypeIn: ["car", "van"]
      tierRelevanceNin: 0
      includeNoPublishable: false
    ) {
      nodes {
        name
        slug
        vehicleType
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        tierRelevance
        publishableUnits
        make {
          name
          slug
        }
        vehicles(categoryIn: "VN", sortByPrice: "desc", first: 1) {
          useCases
          vehicleConfig {
            hero {
              file
            }
          }
        }
      }
    }
  }
`

export const relevanceCarKm0 = gql`
  query relevanceCarKm0($financialEntityName: String!) {
    vehicles: vehicles(
      page: 1
      perPage: 8
      sortByRelevance: true
      categoryIn: "Km0"
      tierRelevanceNin: 0
    ) {
      nodes {
        uid
        registrationDate
        kilometres
        salesPricing(financialEntityName: $financialEntityName)
        tierRelevance
        manufacturerRef
        hero {
          file
        }
        upFrontPayment(entity: $financialEntityName)
        make {
          name
          slug
        }
        model {
          name
          slug
          vehicleType
          publishableUnits
        }
        version {
          slug
          name
        }
        vehicleConfig {
          slug
          hero {
            file
          }
        }
      }
    }
  }
`

export const relevanceCarVo = gql`
  query relevanceCarVo($financialEntityName: String!) {
    vehicles: vehicles(
      page: 1
      perPage: 8
      sortByRelevance: true
      categoryIn: "VO"
      tierRelevanceNin: 0
    ) {
      nodes {
        uid
        registrationDate
        kilometres
        salesPricing(financialEntityName: $financialEntityName)
        tierRelevance
        manufacturerRef
        hero {
          file
        }
        upFrontPayment(entity: $financialEntityName)
        make {
          name
          slug
        }
        model {
          name
          slug
          vehicleType
          publishableUnits
        }
        version {
          slug
          name
        }
        vehicleConfig {
          slug
          hero {
            file
          }
        }
      }
    }
  }
`

export const relevanceMotorbike = gql`
  query relevanceMotorbike($financialEntityName: String!) {
    vehicles: versions(
      model: { vehicleTypeLike: "motorbike" }
      sortByRelevance: true
      page: 1
      perPage: 8
      tierRelevanceNin: 0
      includeNoPublishable: false
      vehicleConfigs: { includeNoPublishable: false }
    ) {
      nodes {
        slug
        name
        salesVnCheapestPrice(financialEntityName: $financialEntityName)
        drivingLicence
        specs
        bodywork
        environmentalLabel
        hero {
          file
        }
        useCases
        publishableUnits(category: "vn")
        model {
          name
          slug
          publishableUnits(category: "vn")
          vehicleType
        }
        make {
          name
          slug
        }
        discounts {
          typeId
          startDate
          percentage
          name
          endDate
          amountCurrency
          amountCents
        }
        vehicleConfigs(sortByPrice: "asc") {
          specs
          publishable
          hero {
            file
          }
          colors {
            name
            surfaceType
          }
        }
      }
    }
  }
`

export const vehiclesCounter = gql`
  query vehiclesCounter {
    vn: vehicles(
      categoryIn: "VN"
      model: { vehicleTypeIn: ["car", "van"], includeNoPublishable: false }
    ) {
      nodesCount
    }
    km0: vehicles(categoryIn: "Km0", model: { vehicleTypeIn: ["car", "van"] }) {
      nodesCount
    }
    vo: vehicles(categoryIn: "VO", model: { vehicleTypeIn: ["car", "van"] }) {
      nodesCount
    }
    motorbike: versions(
      includeNoPublishable: false
      model: { vehicleTypeLike: "motorbike" }
      vehicleConfigs: { includeNoPublishable: false }
    ) {
      nodesCount
    }
  }
`

export default {
  makesCar,
  makesMotorbike,
  relevanceCarVn,
  relevanceCarKm0,
  relevanceCarVo,
  relevanceMotorbike,
  vehiclesCounter,
}
