<template>
  <div
    v-if="banners?.length"
    :class="[
      xClass,
      {
        'container-columns container-columns--responsive-flex s-gap': banners?.length > 1,
      },
    ]"
  >
    <PartialsBanner v-if="banners.length === 1" :banner="banners[0]" :locales="['es_ES']" />
    <template v-else>
      <div class="container-columns__column">
        <PartialsBanner :banner="banners[0]" :locales="['es_ES']" />
      </div>
      <div class="container-columns__column">
        <PartialsBanner :banner="banners[1]" :locales="['es_ES']" />
      </div>
    </template>
  </div>
</template>

<script>
// Components
import PartialsBanner from '@/components/partials/Banner.vue'

export default {
  name: 'ModulesBanners',
  components: {
    PartialsBanner,
  },
  props: {
    xClass: {
      type: String,
      required: false,
      default: '',
    },
  },
  data() {
    return {
      banners: [],
    }
  },
  mounted() {
    this.$nextTick(async () => {
      await this.$store.dispatch('campaign/fetchData', this.$route.query)

      let bannersTouched = 0

      let banners = [
        {
          name: 'astara-selection',
          path: this.localePath('astara-selection'),
          images: {
            desktop: 'desktop.webp',
            tablet: 'tablet.webp',
            mobile: 'mobile.webp',
          },
          alt: this.$t('global.alts.banner_astara_selection'),
        },
        {
          name: 'eco-vehicles',
          path: this.localePath('eco-vehicles'),
          images: {
            desktop: 'desktop.webp',
            tablet: 'tablet.webp',
            mobile: 'mobile.webp',
          },
          alt: this.$t('global.alts.banner_eco_vehicles'),
        },
      ]

      // Campaigns banners
      const campaings = this.$store.getters['campaign/getActiveCampaigns']

      const campaignsWithBanner = campaings?.filter((campaign) => campaign?.campaignBanner) || []

      for (const campaign of campaignsWithBanner) {
        const bannerData = campaign?.campaignBanner

        const banner = {
          images_url: {
            desktop:
              bannerData?.contentAlignment === 'full'
                ? bannerData?.desktopFull
                : bannerData?.desktopNotFull,
            tablet:
              bannerData?.contentAlignment === 'full'
                ? bannerData?.tabletFull
                : bannerData?.tabletNotFull,
            mobile:
              bannerData?.contentAlignment === 'full'
                ? bannerData?.mobileFull
                : bannerData?.mobileNotFull,
          },
          alt: this.$t('global.alts.banner', { name: campaign?.name }),
        }

        if (bannerData?.url) {
          banner.external_url = bannerData.url
        } else {
          banner.path =
            campaign?.slug === 'blackfriday'
              ? this.localePath('/blackfriday')
              : this.localePath({ name: 'campaigns-slug', params: { slug: campaign?.slug } })
        }

        if (bannerData?.contentAlignment === 'full') {
          banners = [banner]
          break
        } else {
          banners[bannerData?.contentAlignment === 'left' ? 0 : 1] = banner
          ++bannersTouched

          if (bannersTouched === 2) break
        }
      }

      this.banners = banners
    })
  },
}
</script>
