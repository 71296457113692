<template>
  <div data-cy="pages-index">
    <Navbar />

    <!-- Header and make-modal browser -->
    <header id="header" class="has-lazy-background">
      <div class="container container--pv">
        <div class="f-light">
          <h1 class="f-uppercase f-size-18 f-500">
            {{ $t('pages.home.header.subtitle') }}
          </h1>
          <h2 class="f-gradient-title">
            {{ $t('pages.home.header.title') }}
          </h2>
          <p class="s-mt-16 f-size-18">
            {{ $t('pages.home.header.description') }}
          </p>
        </div>

        <HomeMakeModelForm />
      </div>

      <PartialsBackground
        :alt="$t('pages.home.header.alt')"
        :load-async="true"
        :load-lazy="false"
        path="home/header"
        x-class="has-lazy-background__background"
      />
    </header>

    <!-- Astara benefits -->
    <ModulesHomeAstaraBenefitsCards id="benefits" />

    <!-- Vehicles searcher by type/category -->
    <section id="categories">
      <div class="container container--pv s-pt-0">
        <header>
          <p class="section-title s-mb-8">
            {{ $t('pages.home.categories.title') }}
          </p>
          <p class="section-subtitle">
            {{ $t('pages.home.categories.subtitle') }}
          </p>
        </header>

        <nav class="categories">
          <div
            class="pseudo-link categories__category"
            @click="() => $router.push(localePath('motorbikes'))"
          >
            <picture>
              <source
                srcset="
                  /images/home/categories/motorbike.webp    1x,
                  /images/home/categories/motorbike@2x.webp 2x
                "
                type="image/webp"
              />
              <img
                decoding="async"
                fetchpriority="low"
                loading="lazy"
                :alt="$t('global.categories_extended_shorted.motorbike')"
              />
            </picture>
            <p class="s-mt-4 f-500 f-size-14 f-uppercase">
              {{ $t('global.categories_extended_shorted.moto')[1] }}
            </p>
          </div>

          <div
            class="pseudo-link categories__category"
            @click="() => $router.push(localePath('cars-vn'))"
          >
            <picture>
              <source
                srcset="/images/home/categories/vn.webp 1x, /images/home/categories/vn@2x.webp 2x"
                type="image/webp"
              />
              <img
                decoding="async"
                fetchpriority="low"
                loading="lazy"
                :alt="$t('global.categories_extended_shorted.vn')"
              />
            </picture>
            <p class="s-mt-4 f-500 f-size-14 f-uppercase">
              {{ $t('global.categories_extended_shorted.vn')[1] }}
            </p>
          </div>

          <div
            class="pseudo-link categories__category"
            @click="() => $router.push(localePath('cars-km0'))"
          >
            <picture>
              <source
                srcset="/images/home/categories/km0.webp 1x, /images/home/categories/km0@2x.webp 2x"
                type="image/webp"
              />
              <img
                decoding="async"
                fetchpriority="low"
                loading="lazy"
                :alt="$t('global.categories_extended_shorted.km0')"
              />
            </picture>

            <p class="s-mt-4 f-500 f-size-14 f-uppercase">
              {{ $t('global.categories_extended_shorted.km0')[1] }}
            </p>
          </div>

          <div
            class="pseudo-link categories__category"
            @click="() => $router.push(localePath('cars-vo'))"
          >
            <picture>
              <source
                srcset="/images/home/categories/vo.webp 1x, /images/home/categories/vo@2x.webp 2x"
                type="image/webp"
              />
              <img
                decoding="async"
                fetchpriority="low"
                loading="lazy"
                :alt="$t('global.categories_extended_shorted.vo')"
              />
            </picture>
            <p class="s-mt-4 f-500 f-size-14 f-uppercase">
              {{ $t('global.categories_extended_shorted.vo')[1] }}
            </p>
          </div>
        </nav>
      </div>
    </section>

    <!-- Vehicles selection categorized by type/category and sorted by relevance -->
    <section v-if="categoryVehiclesOptions?.length" id="catalog" class="c-background-secondary">
      <header>
        <div class="container container--pv s-pb-0">
          <h2 class="section-title s-mb-8">
            {{ $t(`pages.home.catalog.title.${categoryVehiclesSelected || 'vo'}`) }}
          </h2>
          <p class="section-subtitle s-mb-0">
            {{ $t('pages.home.catalog.subtitle') }}
          </p>
        </div>
      </header>

      <div
        v-for="category in ['all', 'vo', 'km0', 'vn', 'motorbike']"
        v-show="categoryVehiclesSelected === category"
        :key="`catalog-${category}`"
        class="slider-container container s-pt-def s-pb-def"
      >
        <Loader v-if="fetchingCategoryVehicles" absolute loading theme="dark" />

        <div
          :id="`slider-${category}`"
          :class="['swiper', 'swiper--default-style', `swiper-${category}`]"
        >
          <div class="swiper-wrapper">
            <div
              v-for="vehicle in categoryVehicles[category]"
              :key="vehicle?.uid || vehicle?.slug"
              class="swiper-slide"
            >
              <CatalogCardSimplified
                :category="vehicle.category"
                :list-name="`vehicles home ${vehicle.category === 'vo' ? 'used' : 'new'} ${
                  vehicle.vehicleType
                }`"
                :event-name="`view-home-page-${vehicle.category === 'vo' ? 'used' : 'new'}`"
                :level="
                  vehicle?.vehicleType === 'motorbike'
                    ? 'version'
                    : vehicle.category === 'vn'
                    ? 'model'
                    : 'vehicle'
                "
                :vehicle="vehicle"
              />
            </div>
          </div>

          <div class="swiper-pagination" />

          <div class="swiper-button-prev">
            <Icon name="chevron_left" />
          </div>
          <div class="swiper-button-next">
            <Icon name="chevron_right" />
          </div>
        </div>
      </div>

      <footer
        class="container container--pv s-pt-0 container-flex container-flex--no-wrap container-flex--justify-space-between s-gap-32"
      >
        <NavbarScrollable
          :options="categoryVehiclesOptions"
          :selection="categoryVehiclesSelected"
          :is-static="isSizeMUp"
          theme="tertiary"
          @submit="(value) => fetchCategoryVehicles(value)"
        />

        <Button
          v-if="isSizeMUp"
          id="gotoCatalogBtn"
          :label="$t('pages.home.catalog.check_out_catalog')"
          :state="categoryVehiclesCatalogPath ? 'enabled' : 'disabled'"
          :to="categoryVehiclesCatalogPath"
          icon-right="east"
          rel="noopener,nofollow"
          size="medium"
          type-theme="secondary-light"
        />
      </footer>

      <div v-if="!isSizeMUp" class="footer-s container container--pv s-pt-0 container-buttons">
        <Button
          id="gotoCatalogBtn"
          :label="$t('pages.home.catalog.check_out_catalog')"
          :state="categoryVehiclesCatalogPath ? 'enabled' : 'disabled'"
          :to="categoryVehiclesCatalogPath"
          icon-right="east"
          rel="noopener,nofollow"
          size="medium"
          type-theme="secondary-light"
        />
      </div>
    </section>

    <!-- Relevant makes categorized by vehicle type and a makes browser -->
    <section v-if="Object.keys(makes)?.length" id="makes">
      <div class="container container--pv">
        <header
          class="s-mb-def s-gap-0 container-flex container-flex--vertical container-flex--align-items-center"
        >
          <h2 class="section-title s-mb-def">
            {{ $t('pages.home.makes.title') }}
          </h2>

          <NavbarScrollable
            v-if="makesRelevantOptions?.length > 1"
            :options="makesRelevantOptions"
            :selection="makesVehicleTypeSelected"
            is-static
            theme="tertiary"
            @submit="(value) => onSelectMakesVehicleType(value)"
          />
        </header>

        <nav class="makes">
          <NuxtLink
            v-for="make in makes[makesVehicleTypeSelected]"
            :key="make?.slug"
            :to="
              localePath({
                name: makesVehicleTypeSelected === 'motorbike' ? 'motorbikes' : 'car-make',
                params:
                  makesVehicleTypeSelected === 'motorbike'
                    ? { facet1: make?.slug }
                    : { make: make?.slug },
              })
            "
            class="f-not-as-link makes__make f-center"
          >
            <img
              :alt="$t('pages.home.makes.make_logo_alt', { name: make?.name })"
              :src="make?.logo?.file"
              decoding="async"
              fetchpriority="low"
              loading="lazy"
            />

            <span class="s-mt-4 f-uppercase f-secondary f-500 f-size-14">
              {{ make?.name }}
            </span>
          </NuxtLink>
        </nav>

        <footer class="container-buttons container-buttons--justify-center s-pt-def">
          <Button
            :loading="fetchingMakes"
            :label="$t('pages.home.makes.check_out_all')"
            size="large"
            type-theme="secondary-light"
            @click="() => (showMakeModelModal = true)"
          />
        </footer>
      </div>
    </section>

    <!-- Banners -->
    <section id="banners" class="c-background-secondary">
      <ModulesBanners x-class="container container--pv" />
    </section>

    <!-- Buying power -->
    <ModulesBuyingPower id="buyingPower">
      <template v-slot:content>
        <h2 class="f-gradient-title s-mb-16" v-html="$t('pages.home.buying_power.title')" />

        <p class="s-mb-16">
          {{ $t('pages.home.buying_power.description') }}
        </p>

        <div class="financial-entities">
          <div
            v-for="financialEntity of financialEntities"
            :key="`financial_entity_${financialEntity}`"
            class="financial-entities__entity"
          >
            <img
              :alt="financialEntity"
              :src="`images/home/financial-entities/${financialEntity}.svg`"
              decoding="async"
              fetchpriority="low"
              loading="lazy"
            />
          </div>
        </div>

        <h3 class="buying-power__content__subtitle">
          {{ $t('pages.home.buying_power.budget.title') }}
        </h3>

        <p>
          {{ $t('pages.home.buying_power.budget.description') }}
        </p>
      </template>
    </ModulesBuyingPower>

    <!-- Info about Astara and relevant reviews -->
    <section id="astara" class="c-background-secondary">
      <ModulesAstara />
    </section>

    <!-- Relevant blog categories -->
    <ModulesRelatedArticles
      id="blog"
      :title="$t('pages.home.blog.title')"
      :articles="relatedBlogCategories"
      card-component="HomeBlogCategoryCard"
      :loading="fetchingBlogCategoriesData"
    >
      <template v-slot:footer>
        <NuxtLink :to="localePath('news')" class="f-as-link f-as-link--with-icon">
          <Icon name="link" size="1.25rem" />
          <span>{{ $t('pages.home.blog.goto_blog') }}</span>
        </NuxtLink>
      </template>
    </ModulesRelatedArticles>

    <!-- FAQs -->
    <section v-if="faqs?.length || fetchingFaqsData" id="faqs" class="c-background-secondary">
      <div class="container container--pv">
        <header>
          <p class="section-title">
            {{ $t('pages.home.faqs.title') }}
          </p>
        </header>

        <Loader v-if="fetchingFaqsData" loading theme="dark" />
        <ul v-else class="faqs ul--clear">
          <li v-for="faq in faqs" :key="faq.uid">
            <NuxtLink
              class="f-as-link f-as-link--with-icon"
              :to="
                localePath({
                  name: 'faqs',
                  query: {
                    faq: stringToSlug(faq.title),
                  },
                })
              "
            >
              <Icon name="link" size="1.25rem" />
              <span>{{ faq.title }}</span>
            </NuxtLink>
          </li>
        </ul>

        <footer class="container-buttons s-mt-def">
          <p v-html="$t('pages.home.faqs.goto_faqs', { path: localePath('faqs') })" />
        </footer>
      </div>
    </section>

    <!-- Glossary of links of interest to the catalog categorized by type/category -->
    <section v-if="Object.keys(glossaries)?.length || fetchingGlossaryData" id="glossary">
      <div class="container container--pv">
        <header class="s-mb-def">
          <p class="section-title s-mb-8">
            {{ $t('pages.home.glossary.title') }}
          </p>
          <p class="section-subtitle">
            {{ $t('pages.home.glossary.subtitle') }}
          </p>
        </header>

        <div class="glossaries">
          <Loader v-if="fetchingGlossaryData" loading theme="dark" />
          <template v-else>
            <div
              v-for="glossary in glossaries"
              :key="`${glossary.vehicleType}_${glossary.category}`"
              class="glossaries__glossary"
            >
              <div>
                <header
                  class="container-flex container-flex--no-wrap container-flex--align-start s-gap-16"
                >
                  <Spinner v-if="fetchingVehiclesCount" />
                  <Badge
                    v-else-if="vehiclesCount[glossary.category]"
                    :label="vehiclesCount[glossary.category]?.nodesCount"
                  />
                  <h3 class="f-size-24 f-size-24--responsive f-500 s-mb-8">
                    {{ $t(`global.categories_extended.${glossary.category}`)[1] }}
                  </h3>
                </header>

                <div v-if="glossary?.bodyworks" class="s-mt-32 glossaries__glossary__section">
                  <p class="f-size-14 f-uppercase f-highlighted s-mt-8 f-700">
                    {{ $t(`pages.home.glossary.bodyworks.${glossary.vehicleType}.title`) }}
                  </p>

                  <ul class="ul--clear ul--cluster">
                    <li v-for="el in glossary.bodyworks" :key="`${el.key}_${el.value}`">
                      <div
                        v-if="glossary.vehicleType === 'car'"
                        class="pseudo-link"
                        @click="() => $router.push(el.path)"
                      >
                        <Tag :label="el.label" theme="neutral" />
                      </div>
                      <NuxtLink v-else :to="el.path" class="f-not-as-link">
                        <Tag :label="el.label" theme="neutral" />
                      </NuxtLink>
                    </li>
                  </ul>
                </div>
              </div>

              <template
                v-for="key in [
                  'catalogLinks',
                  'fueltypes',
                  'kilometersRange',
                  'pricingRange',
                  'drivingLicenses',
                  'environmentalLabels',
                ]"
              >
                <div v-if="glossary[key]" :key="key" class="s-mt-32 glossaries__glossary__section">
                  <p class="f-size-14 f-uppercase f-highlighted s-mt-8 f-700">
                    {{ $t(`pages.home.glossary.${glossary[key][0].key}.title`) }}
                  </p>

                  <ul
                    :class="[
                      'ul--clear',
                      {
                        'ul--cluster': ['drivingLicenses', 'environmentalLabels'].includes(key),
                      },
                    ]"
                  >
                    <li v-for="el in glossary[key]" :key="`${el.key}_${el?.value}`">
                      <div
                        v-if="['drivingLicenses', 'environmentalLabels'].includes(key)"
                        class="pseudo-link"
                        @click="() => $router.push(el.path)"
                      >
                        <Icon
                          :name="`${key === 'environmentalLabels' ? 'env' : 'license'}-${el.value}`"
                          size="2rem"
                        />
                      </div>
                      <div
                        v-else
                        class="pseudo-link f-as-link f-as-link--tertiary"
                        @click="() => $router.push(el.path)"
                      >
                        <span>{{ el.label }}</span>
                      </div>
                    </li>
                  </ul>
                </div>
              </template>
            </div>
          </template>
        </div>
      </div>
    </section>

    <!-- Modals -->
    <ModalsMakeModelBrowser
      v-if="makesOptions[makesVehicleTypeSelected]"
      :open="showMakeModelModal"
      home
      search-by="make"
      :vehicle-type="makesVehicleTypeSelected"
      :title="this.$t('pages.home.make_model_form.modal.make_title')"
      :data="makesOptions[makesVehicleTypeSelected]"
      @selected-make="(value) => onSelectMake(value)"
      @close="() => (showMakeModelModal = false)"
    />
  </div>
</template>

<script>
// Dependencies
import { mapGetters } from 'vuex'
import { Swiper, Navigation, Pagination } from 'swiper'
import 'swiper/swiper.min.css'
import 'swiper/modules/pagination/pagination.min.css'
import 'swiper/modules/navigation/navigation.min.css'

// Queries
import {
  makesCar,
  makesMotorbike,
  relevanceMotorbike,
  relevanceCarVn,
  relevanceCarKm0,
  relevanceCarVo,
  vehiclesCounter,
} from '@/queries/home'
// Queries
import { makes as makesQuery } from '@/queries/makeModelBrowser'

// Helpers & mixins
import checkViewportSize from '@/mixins/checkViewportSize'
import { getStructuredFaqsList } from '@/helpers/seo'
import { stringToSlug } from '@/helpers/urls'
import { toKms } from '@/helpers/numbers'
import { toCurrency } from '@/helpers/currency'
import { drivingLicenses, fuelTypes, powertrains } from '@/helpers/catalog'

// Components
import Button from '@/components/Button.vue'
import CatalogCardSimplified from '@/components/catalog/CardSimplified.vue'
import HomeMakeModelForm from '@/components/home/MakeModelForm.vue'
import Icon from '@/components/Icon.vue'
import Loader from '@/components/Loader.vue'
import ModalsMakeModelBrowser from '@/components/modals/MakeModelBrowser.vue'
import ModulesAstara from '@/components/modules/Astara.vue'
import ModulesBanners from '@/components/modules/Banners.vue'
import ModulesBuyingPower from '@/components/modules/BuyingPower.vue'
import ModulesHomeAstaraBenefitsCards from '@/components/home/AstaraBenefitsCards.vue'
import ModulesRelatedArticles from '@/components/modules/RelatedArticles.vue'
import Navbar from '@/components/Navbar.vue'
import NavbarScrollable from '@/components/NavbarScrollable.vue'
import PartialsBackground from '@/components/partials/Background.vue'
import Spinner from '@/components/Spinner.vue'

export default {
  name: 'PagesHome',
  mixins: [checkViewportSize],
  components: {
    Badge: () => import('@/components/Badge.vue'),
    Button,
    CatalogCardSimplified,
    HomeMakeModelForm,
    Icon,
    Loader,
    ModalsMakeModelBrowser,
    ModulesAstara,
    ModulesBanners,
    ModulesBuyingPower,
    ModulesHomeAstaraBenefitsCards,
    ModulesRelatedArticles,
    Navbar,
    NavbarScrollable,
    PartialsBackground,
    Spinner,
    Tag: () => import('@/components/Tag.vue'),
  },
  data() {
    return {
      categoryVehicles: {},
      categoryVehiclesOptions: [],
      categoryVehiclesSelected: undefined,
      categoryVehiclesSliderRef: {},
      categoryVehiclesSliders: {},
      faqs: [],
      fetchingBlogCategoriesData: true,
      fetchingCategoryVehicles: false,
      fetchingFaqsData: true,
      fetchingGlossaryData: true,
      fetchingMakes: true,
      fetchingVehiclesCount: true,
      glossaries: [],
      makes: [],
      makesOptions: {},
      makesRelevantOptions: [],
      makesVehicleTypeSelected: 'car',
      ready: false,
      relatedBlogCategories: [],
      showMakeModelModal: false,
      vehiclesCount: undefined,
      financialEntities: [
        'BBVA',
        'Sabadell',
        'Santander',
        'Abanca',
        'Cetelem',
        'Autobank',
        'CaixaBank',
        'Sofinco',
        'Lendrock',
      ],
    }
  },
  async asyncData({ app, error, i18n, store, route }) {
    try {
      const client = app.apolloProvider.defaultClient

      // Fetch makes
      const makes = {}
      const makesRelevantOptions = []
      let makesVehicleTypeSelected = 'car'

      makes.car = await client
        .query({
          query: makesCar,
        })
        .then(({ data }) => {
          return data?.makes?.nodes || []
        })

      if (makes.car?.length) {
        makesRelevantOptions.push({
          key: 'car',
          value: i18n.t('global.vehicle_types.car')[1],
        })
      }

      makes.motorbike = await client
        .query({
          query: makesMotorbike,
        })
        .then(({ data }) => {
          return data?.makes?.nodes || []
        })

      if (makes.motorbike?.length) {
        makesRelevantOptions.push({
          key: 'motorbike',
          value: i18n.t('global.vehicle_types.motorbike')[1],
        })

        if (!makes.car?.length) {
          makesVehicleTypeSelected = 'motorbike'
        }
      }

      // Fetch VO vehicles by relevance
      const categoryVehicles = {}

      const relevanceVariables = {
        financialEntityName: process.env.defaultFinancialEntity,
      }

      categoryVehicles.vo = await client
        .query({
          query: relevanceCarVo,
          variables: relevanceVariables,
        })
        .then(({ data }) => {
          const vehicles = data?.vehicles?.nodes || []

          if (vehicles?.length) {
            vehicles.forEach((vehicle) => {
              vehicle.category = 'vo'
              vehicle.vehicleType = 'car'
            })
          }

          return vehicles
        })

      return {
        makes,
        makesRelevantOptions,
        makesVehicleTypeSelected,
        categoryVehicles,
      }
    } catch ({ response = {} }) {
      app.$logger('Error fetching data', response)

      error({
        statusCode: response?.status,
        message: response?.body,
      })
    }
  },
  computed: {
    ...mapGetters({
      bodyworkOptions: 'catalog/getBodyworks',
      environmentalLabelsOptions: 'catalog/getEnvironmentalLabels',
    }),
    categoryVehiclesCatalogPath() {
      let path
      if (this.categoryVehiclesSelected === 'motorbike') {
        path = 'motorbikes'
      } else {
        path = `cars-${this.categoryVehiclesSelected || 'vo'}`
      }

      return this.localePath(path)
    },
  },
  methods: {
    stringToSlug(str) {
      return stringToSlug(str)
    },
    async fetchCategoryVehicles(category) {
      if (this.categoryVehicles[category] && this.categoryVehicles[category].length) {
        this.categoryVehiclesSelected = category
        return
      }

      const { app } = this.$nuxt.context

      try {
        this.fetchingCategoryVehicles = true

        const client = app.apolloProvider.defaultClient

        const relevanceVariables = {
          financialEntityName: process.env.defaultFinancialEntity,
        }

        switch (category) {
          case 'km0':
            this.categoryVehicles.km0 = await client
              .query({
                query: relevanceCarKm0,
                variables: relevanceVariables,
              })
              .then(({ data }) => {
                const vehicles = data?.vehicles?.nodes || []

                if (vehicles?.length) {
                  vehicles.forEach((vehicle) => {
                    vehicle.category = 'km0'
                    vehicle.vehicleType = 'car'
                  })
                }

                return vehicles
              })
            break
          case 'vn':
            this.categoryVehicles.vn = await client
              .query({
                query: relevanceCarVn,
                variables: relevanceVariables,
              })
              .then(({ data }) => {
                const vehicles = data?.vehicles?.nodes || []

                if (vehicles?.length) {
                  vehicles.forEach((vehicle) => {
                    vehicle.category = 'vn'
                    vehicle.vehicleType = 'car'
                  })
                }

                return vehicles
              })
            break
          case 'motorbike':
            this.categoryVehicles.motorbike = await client
              .query({
                query: relevanceMotorbike,
                variables: relevanceVariables,
              })
              .then(({ data }) => {
                const vehicles = data?.vehicles?.nodes || []

                if (vehicles?.length) {
                  vehicles.forEach((vehicle) => {
                    vehicle.category = 'vn'
                    vehicle.vehicleType = 'motorbike'
                  })
                }

                return vehicles
              })
            break
        }

        this.categoryVehiclesSelected = category
        this.setupCategoryVehiclesSlider(category)
      } catch (err) {
        this.$logger('Error fetching category vehicles', err)
      } finally {
        this.fetchingCategoryVehicles = false
      }
    },
    async fetchFaqsData() {
      const { app } = this.$nuxt.context

      let faqs = []

      try {
        this.fetchingFaqsData = true

        const categories = await app.$companyService.listFaqs()
        const reducedCategories = categories.reduce((acc, item) => {
          acc[item.title] = item.faqs
          return acc
        }, {})

        faqs = reducedCategories['Garantía']
      } catch (err) {
        this.$logger('Error fetching FAQs', err)

        faqs = []
      } finally {
        this.faqs = faqs
        this.fetchingFaqsData = false
      }
    },
    async fetchBlogCategoriesData() {
      const { $prismic } = this.$nuxt.context

      let relatedBlogCategories = []

      try {
        this.fetchingBlogCategoriesData = true

        const categoryIds = ['ZFovyBEAACMAt9y6', 'ZHeDQREAACIAetS3', 'ZFtA9hEAACIAvM_i']

        for (const categoryId of categoryIds) {
          const data = await $prismic.api.query(
            $prismic.predicate.at('my.categorypage.category', categoryId),
          )

          if (data?.results_size > 0) {
            relatedBlogCategories.push(data.results[0])
          }
        }
      } catch (err) {
        this.$logger('Error fetching blog articles', err)

        relatedBlogCategories = []
      } finally {
        this.relatedBlogCategories = relatedBlogCategories
        this.fetchingBlogCategoriesData = false
      }
    },
    async fetchGlossaryData() {
      const { store } = this.$nuxt.context

      let glossaries = {}

      try {
        this.fetchingGlossaryData = true
        // Motorbikes
        await store.dispatch('catalog/checkCatalogOptions', {
          vehicleType: 'motorbike',
          category: 'vn',
        })

        glossaries.motorbike = {
          vehicleType: 'motorbike',
          category: 'motorbike',
          bodyworks: this.bodyworkOptions.map((el) => {
            return {
              key: 'bodyworks',
              value: el,
              label: this.$t(`filters.labels.${el}`),
              path: this.localePath({
                name: 'motorbikes',
                params: { facet1: this.$t(`filters.${el}`) },
              }),
            }
          }),
          catalogLinks: [
            {
              key: 'catalog_links',
              label: this.$t('pages.home.glossary.catalog_links.recommended')[1],
              path: this.localePath({
                name: 'motorbikes',
                query: { order: this.$t('filters.relevance') },
              }),
            },
            {
              key: 'catalog_links',
              label: this.$t('pages.home.glossary.catalog_links.cheapest_price')[1],
              path: this.localePath({
                name: 'motorbikes',
                query: {
                  precio_min: 1500,
                  precio_max: 6000,
                  order: this.$t('filters.asc'),
                },
              }),
            },
            {
              key: 'catalog_links',
              label: this.$t('pages.home.glossary.catalog_links.cheapest_installment'),
              path: this.localePath({
                name: 'motorbikes',
                query: {
                  cuota_min: 30,
                  cuota_max: 100,
                  order: this.$t('filters.asc'),
                },
              }),
            },
          ],
          drivingLicenses: drivingLicenses.map((el) => {
            return {
              key: 'driving_licenses',
              value: el,
              label: el,
              path: this.localePath({
                name: 'motorbikes',
                query: { tipo_de_carnet: this.$t(`filters.${el.toLowerCase()}`) },
              }),
            }
          }),
          environmentalLabels: this.environmentalLabelsOptions.map((el) => {
            return {
              key: 'environmental_labels',
              value: el.toLowerCase(),
              label: this.$t(`filters.labels.${el.toLowerCase()}`),
              path: this.localePath({
                name: 'motorbikes',
                params: { facet1: this.$t(`filters.${el.toLowerCase()}`) },
              }),
            }
          }),
        }

        // Cars
        for (const category of ['vo', 'km0', 'vn']) {
          await store.dispatch('catalog/checkCatalogOptions', {
            vehicleType: 'car',
            category,
          })

          glossaries[category] = {
            vehicleType: 'car',
            category,
            bodyworks: this.bodyworkOptions.map((el) => {
              return {
                key: 'bodyworks',
                value: el,
                label: this.$t(`filters.labels.${el}`),
                path: this.localePath({
                  name: `cars-${category}`,
                  params: { facet1: this.$t(`filters.${el}`) },
                }),
              }
            }),
            catalogLinks: [
              {
                key: 'catalog_links',
                label: this.$t('pages.home.glossary.catalog_links.recommended')[0],
                path: this.localePath({
                  name: `cars-${category}`,
                  query: { order: this.$t('filters.relevance') },
                }),
              },
              {
                key: 'catalog_links',
                label: this.$t('pages.home.glossary.catalog_links.cheapest_price')[0],
                path: this.localePath({
                  name: `cars-${category}`,
                  query: {
                    precio_min: 5000,
                    precio_max: category === 'vo' ? 10000 : 20000,
                    order: this.$t('filters.asc'),
                  },
                }),
              },
              {
                key: 'catalog_links',
                label: this.$t('pages.home.glossary.catalog_links.cheapest_installment'),
                path: this.localePath({
                  name: `cars-${category}`,
                  query: {
                    cuota_min: 100,
                    cuota_max: category === 'vo' ? 150 : category === 'km0' ? 200 : 300,
                    order: this.$t('filters.asc'),
                  },
                }),
              },
            ],
            fueltypes: [...fuelTypes, ...powertrains].map((el) => {
              return {
                key: 'fueltypes',
                value: el.toLowerCase(),
                label: this.$t(`filters.labels.${el.toLowerCase()}`),
                path: this.localePath({
                  name: `cars-${category}`,
                  params: { facet1: this.$t(`filters.${el.toLowerCase()}`) },
                }),
              }
            }),
            kilometersRange: undefined,
            pricingRange: undefined,
            environmentalLabels: this.environmentalLabelsOptions.map((el) => {
              return {
                key: 'environmental_labels',
                value: el.toLowerCase(),
                label: this.$t(`filters.labels.${el.toLowerCase()}`),
                path: this.localePath({
                  name: `cars-${category}`,
                  params: { facet1: this.$t(`filters.${el.toLowerCase()}`) },
                }),
              }
            }),
          }

          if (category === 'vo') {
            glossaries[category].kilometersRange = [
              [5000, 10000],
              [10000, 20000],
              [20000, 40000],
              [40000, undefined],
            ].map((el) => {
              const query = { kilometros_min: el[0] }

              let label
              if (el[1]) {
                label = this.$t('global.between_a_and_b', { a: toKms(el[0]), b: toKms(el[1]) })
                query.kilometros_max = el[1]
              } else {
                label = this.$t('global.more_than', { value: toKms(el[0]) })
              }

              return {
                key: 'kilometers_range',
                value: el,
                label,
                path: this.localePath({
                  name: `cars-${category}`,
                  query,
                }),
              }
            })
          }

          if (['vn', 'km0'].includes(category)) {
            glossaries[category].pricingRange = [
              [500000, 2500000],
              [2500000, 4000000],
              [4000000, 6000000],
              [6000000, undefined],
            ].map((el) => {
              const query = { precio_min: el[0] / 100 }

              let label
              if (el[1]) {
                label = this.$t('global.between_a_and_b', {
                  a: toCurrency(el[0]),
                  b: toCurrency(el[1]),
                })
                query.precio_max = el[1] / 100
              } else {
                label = this.$t('global.more_than', { value: toCurrency(el[0]) })
              }

              return {
                key: 'pricing_range',
                value: el,
                label,
                path: this.localePath({
                  name: `cars-${category}`,
                  query,
                }),
              }
            })
          }
        }
      } catch (err) {
        this.$logger('Error fetching glossaries data', err)

        glossaries = {}
      } finally {
        this.glossaries = glossaries
        this.fetchingGlossaryData = false
      }
    },
    async fetchVehiclesCountData() {
      const { app } = this.$nuxt.context

      let vehiclesCount

      try {
        this.fetchingVehiclesCount = true

        const client = app.apolloProvider.defaultClient

        vehiclesCount = await client
          .query({
            query: vehiclesCounter,
          })
          .then(({ data }) => {
            return data || []
          })
      } catch (err) {
        this.$logger('Error fetching vehicles count', err)
        vehiclesCount = undefined
      } finally {
        this.vehiclesCount = vehiclesCount
        this.fetchingVehiclesCount = false
      }
    },
    async fetchMakesData() {
      if (this.makesOptions[this.makesVehicleTypeSelected]) return

      const { app } = this.$nuxt.context

      let makesOptions = this.makesOptions || {}

      try {
        this.fetchingMakes = true

        const client = app.apolloProvider.defaultClient

        makesOptions[this.makesVehicleTypeSelected] = await client
          .query({
            query: makesQuery,
            variables: {
              vehicleTypeIn: this.makesVehicleTypeSelected === 'car' ? ['car', 'van'] : 'motorbike',
            },
          })
          .then(({ data }) => {
            return data?.makes?.nodes || []
          })
      } catch (err) {
        this.$logger('Error fetching makes', err)
        makesOptions = {}
      } finally {
        this.makesOptions = makesOptions
        this.fetchingMakes = false
      }
    },
    setupCategoryVehiclesSlider(category) {
      if (!this.categoryVehicles[category]?.length || this.categoryVehiclesSliders[category]) return

      const SWIPER_CONFIG = {
        lazy: true,
        lazyPreloadPrevNext: 1,
        modules: [Navigation, Pagination],
        loop: false,
        preventClicks: true,
        preventInteractionOnTransition: true,
        slidesPerView: 1,
        spaceBetween: 20,
        speed: 600,
        threshold: 0.5,
        pagination: {
          el: '.swiper-pagination',
          type: 'bullets',
          clickable: false,
          dynamicBullets: true,
          dynamicMainBullets: 1,
        },
        navigation: {
          nextEl: '.swiper-button-next',
          prevEl: '.swiper-button-prev',
        },
        breakpoints: {
          // When window width is >= X px
          768: {
            lazyPreloadPrevNext: 1,
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1024: {
            lazyPreloadPrevNext: 2,
            slidesPerView: 3,
            spaceBetween: 24,
          },
          1280: {
            lazyPreloadPrevNext: 3,
            slidesPerView: 4,
            spaceBetween: 32,
          },
          1440: {
            lazyPreloadPrevNext: 3,
            slidesPerView: 4,
            spaceBetween: 46,
          },
        },
      }

      this.categoryVehiclesSliders[category] = new Swiper(
        document.querySelector(`#slider-${category}`),
        SWIPER_CONFIG,
      )
    },
    onSelectMakesVehicleType(vehicleType) {
      this.makesVehicleTypeSelected = vehicleType
      this.fetchMakesData()
    },
    onSelectMake(make) {
      let path
      if (this.makesVehicleTypeSelected === 'car') {
        path = this.localePath({ name: 'car-make', params: { make: make?.slug } })
      } else {
        path = this.localePath({ name: 'motorbikes', params: { facet1: make?.slug } })
      }

      this.$router.push(path)
    },
  },
  jsonld() {
    return [
      {
        '@context': 'https://schema.org',
        '@type': 'FAQPage',
        mainEntity: getStructuredFaqsList(this.faqs),
      },
    ]
  },
  created() {
    this.categoryVehiclesOptions = [
      {
        key: 'vo',
        value: this.$tc('global.categories.vo', 0),
      },
      {
        key: 'km0',
        value: this.$tc('global.categories.km0', 0),
      },
      {
        key: 'vn',
        value: this.$tc('global.categories.vn', 0),
      },
      {
        key: 'motorbike',
        value: this.$tc('global.categories.motorbike', 0),
      },
    ]
  },
  mounted() {
    // Setup sliders
    this.$nextTick(() => {
      this.categoryVehiclesSelected = 'vo'
      this.setupCategoryVehiclesSlider(this.categoryVehiclesSelected)
      this.categoryVehiclesSliders[this.categoryVehiclesSelected].update()
    })

    // Fetch deferred data asynchronously without blocking the DOM load
    setTimeout(() => {
      this.ready = true
      this.fetchFaqsData()
      this.fetchBlogCategoriesData()
      this.fetchGlossaryData()
      this.fetchVehiclesCountData()
      this.fetchMakesData()
    }, 0)
  },
  head() {
    const headContent = this.$generatePageMetasFromKey('pages.home')

    // headContent.link.push(
    //   {
    //     rel: 'preload',
    //     href: '/images/home/header/desktop-1.webp',
    //     as: 'image',
    //   },
    //   {
    //     rel: 'preload',
    //     href: '/images/home/header/mobile.webp',
    //     as: 'image',
    //   },
    // )

    return headContent
  },
}
</script>

<style lang="scss" scoped>
p:not(.section-title, .section-subtitle) {
  margin: 0;

  & + p {
    margin-top: 1.4em;
  }
}

#header {
  .has-lazy-background__background {
    z-index: -1;
    background-color: $c-primary-900;
  }

  > .container {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: space-between;
    min-height: 42.5rem;
    gap: var(--s-gap);

    > * {
      width: 37.25rem;
      max-width: 50%;
    }

    @include size-m {
      flex-direction: column;
      justify-content: center;
      gap: 0;

      @include size-m-up {
        min-height: calc(100vh - var(--navbar-height));

        @supports (min-height: 100dvh) {
          min-height: calc(100dvh - var(--navbar-height));
        }

        :deep(.make-model-form) {
          margin-top: auto;
        }
      }

      > * {
        max-width: 100%;
      }

      :deep(.make-model-form) {
        margin-top: 2.5rem;
      }
    }
  }
}

#categories {
  position: relative;

  &::before {
    content: '';
    position: absolute;
    z-index: -1;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60%;
    background: $c-secondary-background-color;
    clip-path: polygon(0 100%, 0 60%, 100% 0, 100% 100%);
  }

  .categories {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 1.25rem;

    @include size-m-up {
      grid-template-columns: repeat(4, 1fr);
      gap: 2.5rem;
    }

    &__category {
      height: fit-content;
      padding: 1rem 0.5rem;
      border-radius: 0.75rem;
      text-align: center;

      img {
        display: block;
        width: 100%;
      }

      @include size-l-up {
        transition: box-shadow 0.3s ease, background-color 0.3s ease;

        &:hover {
          background-color: $c-white;
          box-shadow: $c-elevation-2;
        }
      }
    }
  }
}

#catalog {
  overflow: hidden;

  :deep(.loader) > div {
    background: none !important;
  }

  .slider-container {
    position: relative;
    min-height: 23.9688rem;

    @include size-xl-up {
      overflow: hidden;
    }
  }

  .swiper {
    overflow: visible;

    .swiper-wrapper {
      align-items: stretch;
    }

    .swiper-slide {
      height: unset !important;

      > * {
        max-width: 31.25rem;
      }
    }

    :deep(.catalog-card) {
      width: 100% !important;
      height: 100% !important;
    }

    @include size-l-up {
      .swiper-pagination {
        display: none;
      }
    }
  }

  footer {
    @include size-m-up {
      :deep(.navbar-scrollable) {
        width: 34.6875rem !important;
        max-width: 100% !important;
        margin: 0 !important;

        .swiper-wrapper {
          width: 34.6875rem !important;
          max-width: 100% !important;
        }

        .navbar-scrollable__item {
          width: calc(20% - 0.1875rem * 2);

          > div {
            padding-right: 1.25rem;
            padding-left: 1.25rem;
          }
        }
      }
    }
  }

  #gotoCatalogBtn {
    min-width: 8.875rem;
  }

  @include size-m {
    display: flex;
    flex-direction: column;

    header {
      order: 1;
    }

    footer {
      order: 2;
      padding-top: 1.25rem !important;
      padding-bottom: 1.5rem;

      @include size-s {
        padding-right: 0;
        padding-left: 0;
      }
    }

    .slider-container {
      order: 3;
      padding-bottom: 0 !important;
    }

    :deep(.navbar-scrollable) .navbar-scrollable__item {
      width: auto !important;
    }

    .footer-s {
      order: 4;
    }
  }
}

#makes {
  @include size-m-up {
    :deep(.navbar-scrollable) {
      width: 25.75rem !important;
      max-width: 100% !important;
      margin: 0 !important;

      .swiper-wrapper {
        width: inherit !important;
        max-width: inherit !important;
      }

      .navbar-scrollable__item {
        width: calc(50% - 0.1875rem * 2);
      }
    }
  }

  .makes {
    --gap: 0.5rem;

    display: flex;
    flex-wrap: wrap;
    align-content: stretch;
    justify-content: center;
    gap: var(--gap);
    width: 32.25rem;
    max-width: 100%;
    margin: 0 auto;

    @include size-m-up {
      --gap: 1.5rem;
    }

    @include size-l-up {
      --gap: 0.75rem;

      flex-wrap: nowrap;
      width: 90%;
    }

    @include size-xl-up {
      --gap: 2rem;
    }

    @include size-xxl-up {
      --gap: 2.5rem;
    }

    &__make {
      display: flex;
      flex-wrap: wrap;
      align-content: space-between;
      justify-content: center;
      width: calc((100% / 4) - var(--gap));
      aspect-ratio: 1 / 1;
      padding: 0.5rem;
      border-radius: 0.75rem;
      cursor: pointer;

      @include size-l-up {
        width: calc((100% / 8) - var(--gap));
      }

      img {
        display: block;
        width: 80%;
        max-width: 100%;
      }

      span {
        display: block;
        width: 100%;
      }

      @include size-l-up {
        transition: box-shadow 0.3s ease;

        &:hover {
          box-shadow: $c-elevation-2;
        }
      }
    }
  }
}

#buyingPower {
  .financial-entities {
    display: grid;
    grid-gap: 0 var(--s-gap);
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: auto;
    margin-bottom: 2rem;

    &__entity {
      display: inline-flex;
      align-items: center;
      justify-content: flex-start;
      width: 100%;
      min-height: 3rem;

      img {
        display: block;
      }
    }

    @include size-m {
      margin-bottom: 1.25rem;

      .financial-entities__entity {
        justify-content: center;
      }
    }

    @include size-s {
      grid-template-columns: repeat(2, 1fr);

      .financial-entities__entity {
        justify-content: flex-start;
      }
    }
  }

  .buying-power__content__subtitle {
    margin-top: 0 !important;
  }

  @include size-m {
    :deep(.container) {
      width: 100%;
      max-width: 100%;

      * {
        text-align: left;
      }
    }
  }
}

#faqs .faqs {
  li + li {
    margin-top: 1rem;
  }

  @include size-l-up {
    columns: 2;
    column-gap: calc(100% / 12);
  }
}

#glossary {
  :deep(.loader) {
    position: absolute;
    top: calc(50% - 4rem);
    left: calc(50% - 4rem);
  }

  .glossaries {
    position: relative;
    min-height: 4rem;

    header {
      min-height: 4rem;
      border-bottom: 0.0625rem solid $c-neutral-500;
    }

    li {
      margin-top: 0.75rem;
    }

    &__glossary {
      header:deep(.badge) {
        transform: translateY(0.25em);
      }

      a {
        display: block;
        cursor: pointer;

        * {
          pointer-events: none;
        }
      }

      &__section p + .ul--cluster {
        margin-top: 0.75rem;
      }

      .pseudo-link,
      a {
        :deep(.tag) {
          padding: 0.25rem 0.5rem;
          border-radius: 0.875rem;

          span {
            color: $c-primary-900;
          }
        }

        @include size-l-up {
          :deep(.tag) {
            transition: background-color 0.3s ease;
          }

          &:hover :deep(.tag) {
            background-color: $c-neutral-600;
          }
        }
      }
    }

    @include size-s {
      .glossaries__glossary + .glossaries__glossary {
        margin-top: 2.5rem;
      }
    }

    @include size-m-up {
      display: grid;
      grid-gap: var(--s-gap);
      grid-template-columns: repeat(2, 1fr);
    }

    @include size-xl-up {
      grid-template-columns: repeat(4, 1fr);
    }
  }
}
</style>
