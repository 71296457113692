<template>
  <component
    :is="banner?.external_url ? 'a' : banner?.path ? 'NuxtLink' : 'div'"
    :aria-label="banner?.alt"
    :href="banner?.external_url ? banner.external_url : undefined"
    :rel="banner?.external_url ? 'nofollow' : undefined"
    :target="banner?.external_url ? '_blank' : '_self'"
    :to="banner?.path"
    class="banner"
    role="link"
  >
    <picture>
      <source v-if="desktopSrc" media="(min-width: 1280px)" :srcset="desktopSrc" />
      <source v-if="tabletSrc" media="(min-width: 1024px)" :srcset="tabletSrc" />
      <source v-if="mobileSrc" :srcset="mobileSrc" />

      <img decoding="async" fetchpriority="low" loading="lazy" :alt="banner?.alt" />
    </picture>
  </component>
</template>

<script>
export default {
  name: 'PartialsBanner',
  props: {
    locales: {
      type: Array,
      default: () => [],
      required: false,
    },
    banner: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      desktopSrc: '',
      tabletSrc: '',
      mobileSrc: '',
    }
  },
  methods: {
    getImageUrl(name, file) {
      return `/images/banners/${name}/${this.$i18n?.locale || 'es_ES'}/${file}`
    },
  },
  created() {
    const locale = this.$i18n?.locale || 'es_ES'

    if (this.banner?.images_url?.desktop) {
      this.desktopSrc = this.banner.images_url.desktop
    } else if (this.banner?.images?.desktop) {
      this.desktopSrc = `/images/banners/${this.banner.name}/${locale}/${this.banner.images.desktop}`
    }

    if (this.banner?.images_url?.tablet) {
      this.tabletSrc = this.banner.images_url.tablet
    } else if (this.banner?.images?.tablet) {
      this.tabletSrc = `/images/banners/${this.banner.name}/${locale}/${this.banner.images.tablet}`
    }

    if (this.banner?.images_url?.mobile) {
      this.mobileSrc = this.banner.images_url.mobile
    } else if (this.banner?.images?.mobile) {
      this.mobileSrc = `/images/banners/${this.banner.name}/${locale}/${this.banner.images.mobile}`
    }
  },
}
</script>

<style lang="scss" scoped>
.banner {
  display: block;
  position: relative;
  border-radius: 0.5rem;

  img {
    display: block;
    width: 100%;
    height: auto;
    border-radius: 0.5rem;
  }
}
</style>
